<template>
	<div class="p-grid">
		<div class="p-col-12">
            <h3 style="color:#ffcc2a;">{{$t('Reclamaciones')+': '+$t('Vehículo')}} </h3>
		</div>
		<div class="p-col-12" v-if="!mostrar">
			<div class="card">
                <DataTable :value="claims" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm">
                    <template #header>
                        <div class="table-header">
							<div>
								<Dropdown class="p-mr-2" v-model="year" :options="$store.state.years" optionLabel="nombre"></Dropdown>
								<Dropdown class="p-mr-2" v-model="mes" :options="$store.state.meses" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')"></Dropdown>
								<Dropdown class="p-mr-2" v-model="opcion" :options="opciones" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')"></Dropdown>
								<Button :disabled ="$store.state.loading" :label="$t('Buscar')" style="width: 110px;" icon="pi pi-search" class="p-button-primary p-mr-2" @click="Buscar()"/>
							</div>
							<div>{{ $t('Registros') }}: {{ claims.length }}</div>
                            <span class="p-input-icon-right">
                                <i class="pi pi-search"/>
                                <InputText v-model="filters['global']" :placeholder="$t('Buscar')+'...'" />
                            </span>
                        </div>
                    </template>
                    <Column :header="$t('Estatus')" field="estado_name" :sortable="true" headerStyle="width: 180px">
                        <template #body="slotProps">
							<strong :style="'color:'+slotProps.data.color" v-if="slotProps.data.estado!=2 && i18n.locale() == 'es'" class="p-mr-2 p-mb-2">
								{{slotProps.data.estado_nombre}}
							</strong>
							<strong :style="'color:'+slotProps.data.color" v-if="slotProps.data.estado!=2 && i18n.locale() == 'en'" class="p-mr-2 p-mb-2">
								{{slotProps.data.estado_name}}
							</strong>
							<strong :style="'color:'+slotProps.data.color" v-if="slotProps.data.estado==2 && i18n.locale() == 'es'" class="p-mr-2 p-mb-2">
								{{slotProps.data.estado2_nombre}}
							</strong>
							<strong :style="'color:'+slotProps.data.color" v-if="slotProps.data.estado==2 && i18n.locale() == 'en'" class="p-mr-2 p-mb-2">
								{{slotProps.data.estado2_name}}
							</strong>
							<i class="pi pi-clock p-mb-2" v-if="slotProps.data.migracion"/>
							<i class="pi pi-bell p-mb-2" v-if="slotProps.data.edit_claim"/>
                        </template>
                    </Column>   
                    <Column field="codigo" :header="$t('Claim')+' #'" :sortable="true" headerStyle="width: 150px">
                        <template #body="slotProps">
                            {{slotProps.data.codigo}}                              
                        </template>
                    </Column>
                    <Column field="poliz" :header="$t('Póliza')+' #'" :sortable="true" headerStyle="width: 200px">
                        <template #body="slotProps">
                            {{slotProps.data.poliz}}                              
                        </template>
                    </Column>
                    <Column field="cliente" :header="$t('Cliente')+' #'" :sortable="true" headerStyle="width: 120px">
                        <template #body="slotProps">
                            {{slotProps.data.cliente}}                              
                        </template>
                    </Column>
                    <Column field="fecha" :header="$t('Fecha del incidente')" :sortable="true" headerStyle="width: 150px">
                        <template #body="slotProps">
                            {{convfech(slotProps.data.fecha)}}
                        </template>
                    </Column>
                    <Column field="nombre" :header="$t('Forence')" :sortable="true" headerStyle="width: 180px">
                        <template #body="slotProps">
                            {{slotProps.data.forenc}}<br>
							<Tag class="p-mr-2" icon="pi pi-exclamation-triangle" severity="warning" :value="$t('Pendiente')" v-if="slotProps.data.forence"></Tag>
                        </template>
                    </Column>
					<Column :header="$t('Acciones')" headerStyle="width: 80px">
						<template #body="slotProps">
							<Button :disabled ="$store.state.loading" icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="Editar(slotProps.data)" v-if="edit  && (slotProps.data.estado==11 || slotProps.data.estado==13)"/>
							<Button :disabled ="$store.state.loading" icon="pi pi-eye" class="p-button-rounded p-button-info p-mr-2" @click="Editar(slotProps.data)" v-if="edit && slotProps.data.estado==14"/> 
						</template>
					</Column>

                </DataTable>
			</div>
        </div>
		<div class="p-col-12 p-md-12" v-if="mostrar">
			<Policy :datos="seleccion.poliz" :detall="seleccion.vehiculo"/>
		</div>
		<div class="p-col-12 p-md-12" v-if="mostrar">
			<Basic :datos="seleccion.poliz" :detall="seleccion.vehiculo"/>
		</div>
		<div class="p-col-12 p-md-12" v-if="mostrar">
			<Accordion>
				<AccordionTab :header="$t('Beneficiario')">
					<Beneficiary :edit="false" :tipo_veh="tipos" :datos="seleccion.beneficiario"/>
				</AccordionTab>
			</Accordion>
		</div>
		<div class="p-col-12 p-md-12" v-if="mostrar">
			<Accordion>
				<AccordionTab :header="$t('Forence')">
					<Detalle :datos="seleccion" :edit="false" :tipo_veh="tipos" :estados="seleccion.estado.code"/>
				</AccordionTab>
			</Accordion>
		</div>

		<div class="p-col-12 p-md-12" v-if="mostrar">
			<Accordion v-if="seleccion.lesiones">
				<AccordionTab :header="$t('Lesiones')">
					<Lesions :datos="seleccion" :edit="false"/>
				</AccordionTab>
			</Accordion>
		</div>
		<div class="p-col-12 p-md-12" v-if="mostrar">
			<Accordion v-if="seleccion.reemplazo">
				<AccordionTab :header="$t('Reemplazo de Vehículo')">
					<Replacement :datos="seleccion" :edit="false"/>
				</AccordionTab>
			</Accordion>
		</div>
		<div class="p-col-12 p-md-12" v-if="mostrar">
			<div class="card">
				<Card>
					<template #title>
						<h4>{{$t('Estatus')}}:  
							<strong :style="'color:'+seleccion.color" v-if="i18n.locale() == 'es'">
								{{seleccion.estado_nombre}}
							</strong>
							<strong :style="'color:'+seleccion.color" v-if="i18n.locale() == 'en'">
								{{seleccion.estado_name}}
							</strong>
						</h4>
					</template>
					<template #content>	
						<div class="p-fluid p-formgrid p-grid">
								<div class="p-field p-col-2">
									<strong>{{$t('Menos Riesgo Propio')}}:</strong> 
								</div>
								<div class="p-field p-col-3">
									<InputNumber id="horizontal" v-model="seleccion.descuento" showButtons buttonLayout="horizontal" 
										decrementButtonClass="p-button-danger" incrementButtonClass="p-button-success" incrementButtonIcon="pi pi-plus" 
										decrementButtonIcon="pi pi-minus" min="0" mode="currency" :currency="$store.state.currency" style="max-width:200px;"
										:disabled="seleccion.estado>13"/>
								</div>
								<div class="p-field p-col-2">
									<InputSwitch v-model="seleccion.deduc_pagado" :disabled="seleccion.estado>13"/>
								</div>
								<div class="p-field p-col-12">
									<Pagos :datos="seleccion" :banc="bancos"/>
								</div>	
								<div class="p-field p-col-6 p-grid">
									<div class="p-field p-col-12">
										{{$t('Lesiones')}}: <strong>{{moneda(seleccion.lesmonto)}}</strong>
									</div>
									<div class="p-field p-col-12" v-if="seleccion.beneficiario.taller_activo">
										{{$t('Beneficiario')}}: 
										<strong>{{moneda(0)}}</strong>
									</div>
									<div class="p-field p-col-12" v-else>
										{{$t('Beneficiario')}}: 
										<strong>{{moneda(parseFloat(seleccion.total)-parseFloat(pagado)-parseFloat(seleccion.descuento))}}</strong>
									</div>
									<div class="p-field p-col-12" v-if="seleccion.beneficiario.taller_activo">
										{{$t('Taller')}}: 
										<strong>{{moneda(parseFloat(seleccion.total)-parseFloat(pagado)-parseFloat(seleccion.descuento))}}</strong>
									</div>
									<div class="p-field p-col-12" v-else>
										{{$t('Taller')}}: 
										<strong>{{moneda(0)}}</strong>
									</div>
								</div>
								<div class="p-field p-col-6 p-grid">
									<div class="p-field p-col-12">
										<h4>{{$t('Pagado')}}: <strong>{{moneda(seleccion.beneficiario.total)}}</strong></h4>
									</div>
									<div class="p-field p-col-12">
										<h4>{{$t('Pagos Pendientes')}}: 
											<strong>{{moneda(parseFloat(seleccion.total)+parseFloat(seleccion.lesmonto)-parseFloat(seleccion.beneficiario.total)-parseFloat(seleccion.descuento))}}</strong>
										</h4>
									</div>
									<h3><strong>{{$t('Total')}}: {{moneda(parseFloat(seleccion.total)+parseFloat(seleccion.lesmonto)-parseFloat(seleccion.descuento))}}</strong></h3>							
								</div>

											
						</div>
					</template>
					<template #footer>
						<Button :disabled ="$store.state.loading" :label="$t('Guardar')" icon="pi pi-check" class="p-button-secondary p-mr-2 p-mb-2" v-if="seleccion.estado==11 || seleccion.estado==13" @click="Guardar(true)"/>
						<Button :disabled ="$store.state.loading" :label="$t('Cancelar')" icon="pi pi-times" class="p-button-danger p-mr-2 p-mb-2" @click="mostrar=false"/>
						<Button :disabled ="$store.state.loading" :label="$t('Enviar a Reclamaciones')" icon="pi pi-check" class="p-button-info p-mr-2 p-mb-2" @click="Guardar(false)" v-if="seleccion.estado==11"/>
					</template>
				</Card>
			</div>
		</div>
	</div>

</template>

<script>
	import API from "../service/API";
	const Consulta = new API('Reclamos','Claim2');
	import { useI18nPlugin } from '@unify/vuex-i18n';
	import Token from "uuid-token-generator";
	import url from "@/service/_URL";
	import { defineAsyncComponent } from "@vue/runtime-core";

	export default {
		components: {
			Policy: defineAsyncComponent(() => import('../comp_claim/Policy.vue')),
			Basic: defineAsyncComponent(() => import('../comp_claim/Basic.vue')),
			Beneficiary: defineAsyncComponent(() => import('../comp_claim/Beneficiary.vue')),
			Detalle: defineAsyncComponent(() => import('../comp_claim/Detalle.vue')),
			Lesions: defineAsyncComponent(() => import('../comp_claim/Lesions.vue')),
			Replacement: defineAsyncComponent(() => import('../comp_claim/Replacement.vue')),
			Pagos: defineAsyncComponent(() => import('../comp_claim/Pagos.vue')),
		},
		data() {
			return {
				year: null,
				mes: null,
				nuevo: false,
				buscar: null,
				opcion: {nombre: 'Todos', name: 'All', code: 0, color: ''},
				opciones: [{nombre: 'Todos', name: 'All', code: 0, color: ''}],
				insert: false,
				edit: false,
				del: false,
				poliza: null,
				filters: {},
				selectedCustomers: null,
				submitted: false,
				size: '60vw',
				seleccion: {},
				mostrar: false,
				tipos: [],
				estados: [],
				validationErrors: {},
				estado: null,
				estado2: null,
				claims: [],
				bancos: [],
				lesion: {},
				pdf: null,
				lpdf: null,
				completo: false,
				pagado: 0,
			}
		},
		created() {
			this.year = this.$store.state.year;
			this.mes = this.$store.state.mes;
			this.url = url;
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.i18n = useI18nPlugin();
			const tokgen = new Token(256);
			this.pdf = tokgen.generate();
			this.lpdf = tokgen.generate();
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Validar(){
				var result = true;
				this.seleccion.beneficiarios.forEach(element => {
					if(element.ref_fecha==null || element.banco == null || element.referencia == null || element.referencia == ''){
						result = false;
					}
				});
				this.completo = result;
			},
			Mostrar() {
				this.mostrar = false;
				this.pagado = 0;
				Consulta.Procesar('Cons_Admin',{
					id: 0,
				}).then(response => {
					//this.$store.state.error = response;
					this.insert = response.insert;
					this.edit = response.edit;
					this.del = response.delete;
					this.tipos = response.result.tipos;
					this.bancos = response.result.bancos;
					var opciones = response.result.opciones.filter(val => val.code == 11 || val.code == 13 || val.code == 14);
					this.opciones = this.opciones.concat(opciones);
				}); 
			},
			Buscar(){
				if(this.mes && this.year && this.opcion){
					this.mostrar = false;
					this.seleccion = {};
					this.claims = [];
					Consulta.Procesar('BuscarAdmin',{
						opcion: this.opcion.code,
						mes: this.mes.code,
						year: this.year.code,
						}).then(response => {
							//this.$store.state.error = response;
							this.claims = response.result;
						});
				}
			},
			Editar(data) {
				this.seleccion = {};
				this.pagado = 0;
				this.lesion = {};
				Consulta.Procesar('Detalle',{
					claim: data,
				}).then(response => {
					//this.$store.state.error = response;
					var selec = {...data};
					selec.poliz = response.result.poliz;
					selec.vehiculo = response.result.vehiculo;
					selec.anexos = response.result.anexos;
					selec.foren = response.result.foren;
					this.$store.state.claim.pagos = response.result.pagos;
					this.$store.state.claim.lension = response.result.lesion;
					selec.beneficiario = response.result.beneficiario;
					this.seleccion = selec;
					this.mostrar = true;
				});
			},
			Guardar(validacion) {
				this.seleccion.pagos = this.$store.state.claim.pagos;
				Consulta.Procesar('Admin',{
					claim: this.seleccion,
					validacion: validacion
				}).then(response => {
					this.$store.state.error = response;
					if (response.result) {
						this.$toast.add({
							severity:'success',
							summary: this.$t('Éxito'), 
							detail: this.$t('Información Procesada'), 
							life: 3000
						});
						this.Buscar();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: this.$t('Advertencia'), 
							detail: this.$t('Error'), 
							life: 3000
						});
					}
				});
			},
			moneda(number){
				return new Intl.NumberFormat("en", {style: "currency", currency: this.$store.state.currency}).format(parseFloat(number).toFixed(2));
			},
			convfech(fecha){
				return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
			},
		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
